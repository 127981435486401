import { StaticImageData } from 'next/image';
import ReviewPhoto1 from '@/images/reviews/energypal-5-star-review-mike-l.png';
import ReviewPhoto2 from '@/images/reviews/energypal-5-star-review-christopher-grijalva.png';
import ReviewPhoto3 from '@/images/reviews/energypal-5-star-review-henna-m.png';

export type Testimonial = {
  quote: string;
  author: string;
  location: string;
  image: StaticImageData;
  rating: number;
};

// Note: You'll need to replace these placeholder images with actual images
// from your assets directory
export const testimonials: Testimonial[] = [
  {
    quote:
      'EnergyPal made the process of going solar so easy! They connected me with the perfect installer for my home, and the savings have been incredible. The team was professional and knowledgeable every step of the way.',
    author: 'Mike L.',
    location: 'Boston, MA',
    image: ReviewPhoto1,
    rating: 5,
  },
  {
    quote:
      'I was hesitant about going solar, but EnergyPal made it simple and transparent. They helped me understand all the options and connected me with a great installer. My energy bills have dropped significantly!',
    author: 'Christopher G.',
    location: 'Los Angeles, CA',
    image: ReviewPhoto2,
    rating: 5,
  },
  {
    quote:
      "The EnergyPal team was amazing! They guided me through the entire process, answered all my questions, and helped me find the perfect solar solution for my home. Couldn't be happier with the results!",
    author: 'Henna M.',
    location: 'Pittsburgh, PA',
    image: ReviewPhoto3,
    rating: 5,
  },
];
