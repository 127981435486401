import Image from 'next/legacy/image';
import { StaticImageData } from 'next/image';

type Testimonial = {
  quote: string;
  author: string;
  location: string;
  image: StaticImageData;
  rating: number;
};

type Props = {
  testimonials: Testimonial[];
};

export default function HomeownerTestimonials({ testimonials }: Props) {
  return (
    <div className="bg-white py-2 sm:py-24">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Why Homeowners Love EnergyPal
          </h2>
          <p className="mt-2 text-lg leading-8 text-gray-600">
            Hear from real homeowners who have made the switch to solar with
            EnergyPal
          </p>
        </div>
        <div className="mx-auto mt-12 grid max-w-2xl grid-cols-1 gap-8 sm:gap-12 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {testimonials.map((testimonial, index) => (
            <article
              key={index}
              className="flex flex-col items-center text-center bg-gray-50 p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300"
            >
              <div className="relative w-14 h-14 rounded-full overflow-hidden mb-3 ring-2 ring-primary-500 ring-offset-2">
                <Image
                  src={testimonial.image}
                  alt={testimonial.author}
                  className="object-cover"
                  layout="fill"
                  quality={100}
                />
              </div>
              <div className="w-full">
                <div className="flex items-center justify-center gap-x-4 text-xs mb-1">
                  <span className="text-gray-500">{testimonial.location}</span>
                </div>
                <div className="group relative">
                  <h3 className="text-lg font-semibold leading-6 text-gray-900">
                    {testimonial.author}
                  </h3>
                  <div className="mt-1 flex items-center justify-center">
                    {[...Array(5)].map((_, i) => (
                      <svg
                        key={i}
                        className={`h-4 w-4 ${
                          i < testimonial.rating
                            ? 'text-yellow-400'
                            : 'text-gray-300'
                        }`}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                      </svg>
                    ))}
                  </div>
                  <p className="mt-3 text-sm leading-6 text-gray-600">
                    {testimonial.quote}
                  </p>
                </div>
              </div>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}
